import React, { useState, useEffect } from "react";
import "./forgotPassword.scss";

import { useTranslation } from 'react-i18next';
import { savePageTranslateLocalStorage,initializeI18n ,getLanguageFromLocalStorage } from "../utilities/localization"
import AuthFooter from './AuthFooter';
import AuthHeader from "./AuthHeader";
import Logo from "../../assets/images/Mohabbatein Logo.svg";
import axios from "axios";
import SuccessImage from "../../assets/images/success.png";

import sideloader from "../../assets/images/sidebuttonloader.gif";

import maintopbanner from "../../assets/images/home-banner-top-dtp.png";
import mainbottombanner from "../../assets/images/home-banner-bottom-dtp.png";


const url = process.env.REACT_APP_PUBLIC_URL;
const urlpublic = process.env.REACT_APP_GUEST_URL;

export default function ForgotPasswordV2() {
  const [email, setEmail] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [message, ResetMessage] = useState("");
  const [resetState, setResetState] = useState(0);
  const [success, setSuccess] = useState(false)
  const language = getLanguageFromLocalStorage() || "en";

  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      await savePageTranslateLocalStorage("auth");
      initializeI18n();
    };
    fetchData();
   
    }, []);


  function handleFormSubmit(e) {
    e.preventDefault();
    // console.log(e);
    // console.log(email);
    let data = {
      email: email,
      language: language,
    };
    try {
      setIsLoading(true);
      setResetState(0);
      axios
        .post(`${urlpublic}forgot-password`, data)
        .then((respond) => {
          // console.log(respond);
          setIsLoading(false);
          setSuccess(true)
          setResetState(1);
        })
        .catch((error) => {
          setIsLoading(false);
          // console.log(error);
          ResetMessage(error?.response?.data?.data);
          setResetState(2);
        });
    } catch (e) {
      setIsLoading(false);
      // console.log(e);
      setResetState(2);
    }
  }

  return (
    <>
      <div id="Resetpassword">
      {/* <AuthHeader /> */}
        <section id="main" className="cus_loginbackground cus_login_design" >
          {/* <div className="left-top heart"></div>
          <div className="left-bot heart"></div> */}
          <div style={{ position: 'absolute', top: 0, left: 0 ,right:0 , textAlign: 'center'  }} >
      <img className="w-100-old img-fluid " src={ maintopbanner } alt="" />
    </div>
          <div id="formwrap" className={success? "formwrap " : "formwrap active"}>
            <form id="reset-form" onSubmit={handleFormSubmit}>
              <div className="text-center">
                <h1 className="heading" style={{ marginBottom: "0px" }}>
                  {(t('forgot-password-heading'))}
                </h1>
                <br />
                <br />

                <div className="step step-1 active">
                  <div className="form-group">
                    <div className="label">{t(['fp-email-label',''])}</div>
                    <input
                      className=""
                      type="email"
                      placeholder={t(['fp-email-placehoder',''])}
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="row navs">

                  <div className="col-12 ">
                      <div className="d-flex justify-content-start" style={{marginTop:'-20px'}}>
                        {resetState === 1 ? <p>{message}</p> : <></>}
                        {resetState === 2 ? <p className="reset-error">{message}</p> : <></>}
                      </div>
                    </div>


                    <div className="col-12">
                      <div className="d-flex justify-content-end">
                        {isLoading ? (
                          <button
                            className="nav-bt submit-loading float-end"
                            disabled
                            style={{ minWidth: "120px" }}
                          >
                            <img
                              src={sideloader}
                              alt={"loader"}
                              style={{
                                height: "50px",
                                marginRight: "-10px",
                                marginTop: "-5px",
                              }}
                            />
                          </button>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className="nav-bt red float-lg-end"
                            >
                              {t(['reset-button',''])}
                            </button>
                          </>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </form>
          </div>

          <div
          id="email-verify"
          className={success ? "formwrap active" : "formwrap"}
        >
          <div className="text-center">
            <h2 className="heading"></h2>
            <img src={SuccessImage} alt="Mohabbatein Success" />
            <br />
            <br />
            <br />
            <h3>{t(['fp-success-heading',''])}</h3>
            <br />
            <p>
              {t(['fp-success-message',''])}
                          </p>
          </div>
        </div>



          {/* <div className="right-top heart"></div>
          <div className="right-bot heart"></div> */}

<div style={{ position: 'absolute', bottom: 0, left: 0  ,right:0, textAlign: 'center'  }}>
      <img className=" w-100-old img-fluid " src={ mainbottombanner } alt="" />
    </div>

        </section>
{/* <AuthFooter /> */}
      </div>
    </>
  );
}
