import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  savePageTranslateLocalStorage,
  initializeI18n,
  getLanguageFromLocalStorage,
} from "../utilities/localization";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./Login.scss";
import { initUserData, initTokenData, clearUserData } from "../actions/Global";
import { initUserObject, clearProfileData } from "../actions/Profile";
import axios from "axios";

import { setClearHome } from "../../app/actions/Home";
import { setClearMessage } from "../../app/actions/Messages";
import { setclearNotifications } from "../../app/actions/Notifications";
import { setClearSettingsData } from "../../app/actions/Settings";

import maintopbanner from "../../assets/images/home-banner-top-dtp.png";
import mainbottombanner from "../../assets/images/home-banner-bottom-dtp.png";


// import AuthFooter from "./AuthFooter";
// import AuthHeader from "./AuthHeader";

const url = process.env.REACT_APP_PUBLIC_URL;

export default function LoginPagetest() {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const language = getLanguageFromLocalStorage() || "en";

  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tranLoading, setTranloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adUser, setAdUser] = useState(false);

  var formData = new FormData();

  const { t } = useTranslation("translation");
  useEffect(() => {
    // localStorage.removeItem('persist:root')
    dispatch(setClearHome());
    dispatch(setClearMessage());
    dispatch(setclearNotifications());

    //
    dispatch(setClearSettingsData());

    const fetchData = async () => {
      await savePageTranslateLocalStorage("auth");
      initializeI18n();
    };
    fetchData();
    // return () => {
    //   fetchData();
    // }

    // const Authtoken = localStorage.getItem('authtoken')
    // if(Authtoken){
    //   navigate('/')
    // }
  }, []);

  useEffect(() => {
    // Assuming you're not using a routing library
    const queryString = window.location.search; // Gets the query string from the URL
    const urlParams = new URLSearchParams(queryString);
    const adUserValue = urlParams.get('ad'); // Gets the value of 'ad_user' parameter
    if (adUserValue === "1") {
      setAdUser(true);
    }

  }, []);


  async function handleFormSubmit(e) {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    console.log(`${url}/auth/login`);

    formData.append("email", email);
    formData.append("password", password);
    formData.append("language", language);
    // dispatch(Login(formData))
    setErrorMessage("");
    setLoginError(false);
    dispatch(clearUserData());
    dispatch(clearProfileData());
    setIsLoading(true);
    try {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("app_version");
      localStorage.removeItem("user_version");
      axios
        .post(`${url}/auth/login`, formData)
        .then(({ data }) => {
          localStorage.setItem("authtoken", data?.data?.token);
          localStorage.setItem("app_version", data?.data?.user?.app_version);
          localStorage.setItem("user_version", data?.data?.user?.main?.id);

          const token = localStorage.getItem("authtoken");
          const app = localStorage.getItem("app_version");
          const user = localStorage.getItem("user_version");

          if (!token || !app || !user) {
            setErrorMessage("We have encountered an error storing local data.");
            setLoginError(true);
          } else {
            dispatch(initTokenData(data?.data?.token));

            // console.log(data?.data?.user)
            // console.log(data)
            dispatch(initUserData(data?.data?.user));
            dispatch(initUserObject(data?.data?.user));

            var formData = new FormData();
            formData.append("slug", data?.data?.user?.slug);
            formData.append("language", language);
            //  dispatch(getProfileData(formData))
            //   console.log(data?.data);
            // navigate("/upgrade-to-premium/");
            if (!data?.data?.user?.profile?.is_premium) {
              //navigate("/upgrade-to-premium/");
              // if(data?.data?.show_trial_page) {
              //   navigate("/start-your-free-trial");
              //   return;
              // } else {
              //   //navigate("/upgrade-to-premium/");
              // }

            }
            if (
              !data?.data?.user?.profile?.height?.full ||
              !data?.data?.user?.profile?.family_value?.name ||
              !data?.data?.user?.profile?.family_type?.name
            ) {
              navigate("/profile");
              return;
            }
            // if(!data?.data?.user?.is_premium){
            //   navigate("/upgrade-to-premium/");
            //   return;
            // }
            setTimeout(() => {
              navigate("/");
            }, 100);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.data);
          setLoginError(true);
          setIsLoading(false);
        });
    } catch (error) {
      // console.log(e);
      dispatch(loginError());
      setIsLoading(false);
    }
  }

  const header = t("login-heading-top");

  return (
    <div id="login" >
      {/* <AuthHeader /> */}
      <section id="main" className="cus_loginbackground cus_login_design">
        {/* <div className="left-top heart" ></div>
        <div className="left-bot heart"></div> */}
        <div style={{ position: 'absolute', top: 0, left: 0, right:0 , textAlign: 'center' }} >
          <img className="w-100-old img-fluid " src={maintopbanner} alt="" />
        </div>

        <div
          id="formwrap"
          onSubmit={handleFormSubmit}
          className="formwrap active"
        >
          <form id="login-form">
            <div className="text-center">
              <h1 className="heading" style={{ marginBottom: "60px" }}>
                {t(["login-heading-top", "Ready to"])} <br />{" "}
                <span className="red-text">
                  {t(["login-heading-bottom", "Find Your Forever?"])}
                </span>
              </h1>

              <div className="step step-1 active">
                <div className="form-group">
                  <div className="label">{t(["email-label", "Email ID"])}</div>
                  <input
                    className=""
                    type="text"
                    placeholder={t(["email-placeholder", "Email Address"])}
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  // placeholder =''
                  />
                </div>
                <div className="form-group">
                  <div className="label">
                    {t(["password-label", "Password"])}
                  </div>
                  <input
                    className=""
                    type="password"
                    placeholder={t(["password-placeholder", "Password"])}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div
                  className={
                    loginError
                      ? "alert alert-danger active"
                      : "alert alert-danger "
                  }
                  id="login-error"
                >
                  {errorMessage}
                </div>
                <div className="form-group reset-formgroup">
                  <a className="reset-password" href="/forgot-password-test/">
                    {t(["forgot-password", "Forgot Password"])}
                  </a>
                </div>

                <div className="row navs">
                  <div className="col-12">
                    <div className="login-buttons">
                      <a href={`/register-test/${adUser ? "?ad=1" : ""}`}>
                        {t(["create-an-account", "Create an account"])}{" "}
                      </a>

                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          style={{ textTransform: "capitalize" }}
                          className="nav-bt red float-end"
                        >
                          {t(["login-button", "Login"])}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>


        <div style={{ position: 'absolute', bottom: 0, left: 0,right:0 , textAlign: 'center'  }}>
          <img className="w-100-old img-fluid" src={mainbottombanner} alt="" />
        </div>

        {/* <div className="right-top heart"></div>
        <div className="right-bot heart"></div> */}
      </section>

      {/* <> */}
      {/* <AuthFooter /> */}
      {/* </> */}
    </div>
  );
}
