import React ,{ Suspense }from "react";
import {
    Routes,
    Route,
    // Switch
  } from "react-router-dom";
import loadable from "@loadable/component";



  
// auth
// pages

// import Main from "../pages/main";
import Register from "../app/auth/Register";
import RegisterV2 from "../app/auth/RegisterV2";
import UnSubscribe from "../app/auth/UnSubscribe";
import Profile from '../pages/profile';
import LoginPage  from '../app/auth/Login';
import LoginPageV2  from '../app/auth/LoginV2';
import LoginPagetest  from '../app/auth/LoginTest';
import ProfileView from "../pages/profile-view";
import Messages from "../pages/messages";
// import Premium  from "../pages/premium";
import ForgotPassword from "../app/auth/ForgotPassword";

import ForgotPasswordV2 from "../app/auth/ForgotPasswordV2";


import ResetPassword from "../app/auth/ResetPassword";
import Settings from '../pages/settings'
// import Blogs from "../pages/blogs";
import Support from "../pages/support";
import Faqs from "../pages/faqs";
import FirstPricingPage from "../pages/first-pricing-page";
import TrialPage from "../pages/trial-page";
// const Main = React.lazy(() => import('../pages/main'));
// const Profile = React.lazy(() => import('../pages/profile'));'

 


const Main = loadable(() => import('../pages/main'));
const Premium = loadable(() => import('../pages/premium'));

const PremiumTelr = loadable(() => import('../pages/premium-telr'));


const Blogs = loadable(() => import('../pages/blogs'));
const IphoneTerms = loadable(() => import('../pages/iphone-terms'));
const AboutUs = loadable(() => import('../pages/about-us'));



const AppRoutes = () => {
  return (
  

        
       <Routes>

          
        {/* <Route  path="/" element={<Main />} /> */}

        {/* <Route  path="/login-test/" element={<LoginPagetest />} /> */}
        <Route  path="/login-test/" element={<LoginPagetest />} />
        <Route  path="/register-test/" element={<RegisterV2 />} />
    
        <Route  path="/forgot-password-test/" element={<ForgotPasswordV2 />} />

        <Route  path="/register/" element={<Register />} />
        <Route  path="/unsubscribe/" element={<UnSubscribe />} />
        <Route  path="/login/" element={<LoginPage />} />
       
        <Route  path="/register/:id" element={<Register />} />
        <Route  path="/profile/" element={<Profile />} />
        <Route  path="/user/:slug" element ={<ProfileView />} />
        <Route  path="/messages/" element ={<Messages />} />
        <Route  path="/blog/" element ={<Blogs />} />
        <Route  path="/contact-us/" element ={<Support />} />
        <Route  path="/frequently-asked-questions/" element ={<Faqs />} />
        {/* <Route  path="/messages?:u" element ={<Messages />} /> */}
  
        <Route  path="/premium/" element ={<Premium />} />
        {/* <Route  path="/premium/" element ={<Premium />} /> */}
        <Route  path="/premium/:plan" element ={<Premium />} />
         
        <Route  path="/premium-plan/" element ={<PremiumTelr />} />

        <Route  path="/forgot-password/" element={<ForgotPassword />} />
  
        <Route  path="/forgot-password/" element={<ForgotPassword />} />
        <Route  path="/reset-password/" element={<ResetPassword />} />
        
        <Route  path="/settings/" element={<Settings />} />
        <Route  path="/upgrade-to-premium/" element={<FirstPricingPage />} />
        <Route  path="/start-your-free-trial/" element={<TrialPage />} />

        <Route  path="/about-us/" element={<AboutUs />} />
        <Route  path="/iphone-terms/" element={<IphoneTerms />} />

        <Route  path="/" element={<Main />} />
        <Route  path="/*" element={<Main />} />
      
      </Routes> 


  );
};

export default AppRoutes;
