import { createSlice } from "@reduxjs/toolkit";
import axiosAuth from "../auth/jwtMiddleware";

const url = process.env.REACT_APP_PUBLIC_URL;
export const homeSlice = createSlice({
    name: "home",
    initialState: {
        userFeedData:[],
        isUserProfilesLoading:false,
        isLoadedMobile:false,
        isFeedLoading :false,
        errorMessage:'',
        errorOnLike:false,
        globalFilters:[],
        userData: [],
        isLoaded:false,
        pageNumber:0,
        queryCount:0,
        currentPage:0,
        initalRequestTime:null
    },
    reducers: {
        startprofileLoading:(state)=>{
            state.isUserProfilesLoading = true;
            state.isFeedLoading = true;
            state.isLoaded = false;
            state.pageNumber=0
        },
        setUserFeedData:(state, action)=>{
            state.isUserProfilesLoading = false;
            state.isFeedLoading = false;
            state.isLoaded = true;
            state.userFeedData = action.payload;
        },
        startprofileLoadingMobile:(state)=>{
            state.isLoadedMobile = false;
        },
        initializeTotalData:(state, action)=>{
            state.queryCount = action.payload;
        },
        initializeQueryPage:(state, action)=>{
            state.currentPage = action.payload;
        },
        initializeProfileData:(state, action)=>{
            state.userFeedData = action.payload;
            state.isUserProfilesLoading = false;
            state.isFeedLoading = false;
            state.isLoaded = true
            state.isLoadedMobile = true;
        },
        initializeEmptyObject:(state)=>{
            state.userFeedData = [];
            state.isUserProfilesLoading = false;
            state.isFeedLoading = false;
            state.isLoaded = true
        },
        errorOnGettingProfileData:(state, action)=>{
            state.isUserProfilesLoading=false;
            state.isFeedLoading = true
            state.errorMessage =action.payload
            state.isLoaded = true
            state.pageNumber =0
        },
        initializeErrorLiking:(state)=>{
            state.errorOnLike = false
           
        },
        errorOnUserLike:(state) =>{
            state.errorOnLike = true
        },
        initializeUserData:(state,action)=>{
            state.userData = action.payload
        },
        initializePageNumber:(state, action)=>{
            state.pageNumber =action.payload
        },
        initializeRequestTime:(state, action)=>{
            state.initalRequestTime =action.payload
        },
        clearLoading:(state)=>{
            state.isFeedLoading = false;
            state.isUserProfilesLoading=false;
            
        },
        clearHomeData :(state)=>{
            state.userFeedData = [];
            state.isUserProfilesLoading = false;
            state.isFeedLoading = false
            state.errorMessage= '';
            state.errorOnLike=false;
            state.globalFilters=[];
            state.userData= []
            state.isLoaded = false
            state.pageNumber =0

        },
        clearuserData :(state) =>{
            state.userData= [];
            state.isLoaded = false;
        },
        clearuserLoader :(state)=>{
            state.isLoaded = false;
        }
    }
});

export const {
    startprofileLoading,
    initializeProfileData,
    initializeEmptyObject,
    setUserFeedData,
    errorOnGettingProfileData,
    initializeErrorLiking,
    errorOnUserLike,
    clearHomeData,
    clearLoading,
    clearuserData,
    initializePageNumber,
    initializeTotalData,
    initializeQueryPage,
    startprofileLoadingMobile,
    initializeRequestTime,
    clearuserLoader
} = homeSlice.actions;
export default homeSlice.reducer;

// export const getProfileData = (data)=>async (dispatch)=>{
export const appyFilters = (filterdata,mobile_request=false) => async (dispatch)=>{
          
          dispatch(startprofileLoading());
            try{
                if(window.innerWidth > 767) {
                    filterdata?.append('is_desktop',true);
                } else {
                    filterdata?.append('is_desktop',false);
                }
console.log("mk userfeed One : "+filterdata); 
                // console.log('try')
                await axiosAuth().post(`${url}/user-feed/get`,filterdata)
                .then(({data})=>{
                    if(data?.status ===200){
                  
           
                        if(data?.current_pagination){
                            dispatch(initializePageNumber(data?.current_pagination))
                        }
                        if(data?.api_first_request_time){
                            dispatch(initializeRequestTime(data?.api_first_request_time))
                        }
                       if(data?.data.length>0){
                        // console.log("cus filter ");
                         // console.log(JSON.stringify(data.data, null, 2));
                            dispatch(initializeProfileData(data?.data))
                            if(data?.queryCount){
                             dispatch(initializeTotalData(data?.queryCount))
                            }
                            dispatch(initializeQueryPage(data?.current_pagination))
                        }
                        
                        else{
                            dispatch(initializeEmptyObject())
                        }

                        dispatch(clearLoading())
                    }
                }
                )
                .catch((error)=>{
                // console.log(error)
                dispatch(errorOnGettingProfileData(error));
                dispatch(clearLoading())
                })

            }
            catch(error){
                    dispatch(errorOnGettingProfileData(error));
                    dispatch(clearLoading())
            }
}


export const setUserFeedDataOld = (data) => async (dispatch)=>{
    dispatch(setUserFeedData(data));
}



export const appyFiltersNext = (filterdata,mobile_request=false) => async (dispatch )=>{

  
    try{
        if(window.innerWidth > 767) {
            filterdata?.append('is_desktop',true);
        } else {
            filterdata?.append('is_desktop',false);
        }
        if(!mobile_request){
            dispatch(startprofileLoading());
        } else{
            dispatch(startprofileLoadingMobile());
        }
        console.log("mk userfeed two : "+filterdata); 
          await axiosAuth().post(`${url}/user-feed/get`,filterdata)
          .then(({data})=>{
              if(data?.status ===200){
                
                  if(data?.current_pagination){
                      dispatch(initializePageNumber(data?.current_pagination))
                  }
                  if(data?.api_first_request_time){
                    dispatch(initializeRequestTime(data?.api_first_request_time))
                }
                  dispatch(initializeProfileData(data?.data))
                  dispatch(initializeQueryPage(data?.current_pagination))
                  dispatch(clearLoading())
              }
          }
          )
          .catch((error)=>{
          // console.log(error)
          dispatch(errorOnGettingProfileData(error));
          dispatch(clearLoading())
          })

      }
      catch(error){
              dispatch(errorOnGettingProfileData(error));
              dispatch(clearLoading())
      }
}



// export const setFilters = {}

export const likeUser = (user) => async (dispatch)=>{
    // console.log(user)
    dispatch(initializeErrorLiking())
    try{
        await axiosAuth().post(`${url}/users/like`,user)
        .then((data)=>{
            // console.log(data?.data?.status)
            if(data?.data?.status){
                // console.log(state.userFeedData)

                // homeSlice.getInitialState()
                // console.log(data?.data)
            }
               
        })
        .catch((error)=>{
            dispatch(initializeErrorLiking());
        })
    }
    catch (error){
        // console.log(error)
    }
} 




export const updateUser = () => async (dispatch)=>{
//    dispatch(initializeUserData()
}

export const setClearHome = () => (dispatch) =>{
    dispatch(clearHomeData())
}


export const setClearLoaders =() => (dispatch) =>{
    dispatch(clearLoading())
}

export const setClearUserdata = () => (dispatch) =>{
    dispatch(clearuserData())
}


export const removeUserLoader =() => (dispatch) =>{
    dispatch(clearuserLoader())
}

export const emptyUserFeedObject = () => (dispatch) =>{
    dispatch(initializeEmptyObject())
}